import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import BlockIcon from '@mui/icons-material/Block';
const tutorialData = [
  {"ss":"ss"},
  {
    title: 'Poseidon Mantığı Nedir?',
    description: 'Klasik bahis sitelerinde oynadığınız kombine kupon 1 maçtan yatınca diğer maçları bilmiş olmanızın bir yararı olmaz. Kaybeden siz olursunuz, Kasa herzaman kazanır sözünden yola çıkarak burda Poseidon u bir oyuncu sizide kasa olmaya davet ediyoruz. Artık herşey tersine döndü; seçtiğiniz maçlardan 1 tanesini dahi bilseniz kazanan siz oluyorsunuz. Maçı siz seçersiniz, seçeneği siz belirlersiniz, oynayacağı miktarı siz belirlersiniz ve Poseidon oynar. Burda amaç Poseidon kaybetmeli, siz kazanmalısınız.',
    images: [
      { src: '/image/path-to-image1.jpg', description: 'Seçtiğiniz maçlardan 1 tanesini bilseniz yine de kazanan siz olursunuz' },
      { src: '/image/path-to-image2.jpg', description: 'Poseidon un oynayacağı Miktarı ve Seçeneği siz belirlersiniz' },
      { src: '/image/path-to-image3.jpg', description: 'Yıldız sayınız arttıkça Poseidon a verdiğiniz komisyon azalır' },
      { src: '/image/path-to-image4.jpg', description: 'Şartlara bağlı kalmadan kendi stratejinizi kendiniz belirlersiniz' }
    ]
  },
  {
    title: 'Yıldız Ne Kazandırır?',
    description: 'Üyeler yıldızı ne kadar fazlaysa o kadar kolay para kazanır.Her bir yıldız sizi daha avantajlı hale getirir,Yıldız sayısına göre hak edilen avantajları sistem otomatik olarak verir herhangi bir talepte bulunmanıza gerek kalmaz. ',
    images: [
        { src: '/image/path-to-image1.jpg', description: '1 Yıldızın maddi değeri haftalık 250 TL dir .Örnek: 80 yıldızı olan üye haftalık 20.000 TL yıldız kazancını her Çarşamba 00:01 de hesabına otomatik olarak alır,çevrim şartı olmadan çekebilir' },
        { src: '/image/path-to-image2.jpg', description: 'Poseidon un aldığı komisyonu yıldız sayısına göre daha az öder. Örnek: 100 yıldızı olan üye %100 indirim kazanacaktır ve Poseidon a hiç komisyon ödememiş olur.' },
        { src: '/image/path-to-image3.jpg', description: 'Zeus slotlarında eğer bir kayıp yaşarsanız daha fazla discount(iade) alırsınız. Bu tarz indirimleri sistem kendiliğinden hesabınıza tanımlayacaktır sizin bir talepte bulunmanıza gerek kalmaz' },
        { src: '/image/path-to-image4.jpg', description: 'Her yıldız 1 çekiliş bileti olarak değerlendirilir.Yıldız sayınız fazlaysa her Hafta Cuma günü dağıtılacak muhteşem ödüllerden daha fazla kazanma şansı elde edersiniz' }
    ]
},        {
  title: 'Yıldız Sayısı Nasıl Artar?',
  description: 'Yıldız sayısını artırmanın 3 yolu vardır',
  images: [
      { src: '/image/path-to-image1.jpg', description: 'Kendi hesabınızda ki yıldız görevlerini yerine getirerek yıldız sayınızı artırabilirsiniz' },
      { src: '/image/path-to-image2.jpg', description: 'Yıldız Yarışında ki ödül çarklarını çevirerek' },
      { src: '/image/path-to-image3.jpg', description: 'Yıldız yarışındaki size özel hazırlanan linkiniz ile reklam kanallarından yeni üyeler bulabilirsiniz ' },
      { src: '/image/path-to-image4.jpg', description: 'Kendinize ait referans kodu ile arkadaşlarınızı üye yaparsanız onların kazanacağı yıldızların yarısı size eklenir' }
  ]
},
{
  title: 'Yıldız Yarışı Nedir?',
  description: 'Yıldız sayınız arttıkça vip müşteri konumuna geçersiniz ve yıldızlarınıza göre çok özel avantajlarınız olur,herhangi bir talepte bulunmanıza gerek kalmadan sistem tarafından yıldız avantajlarınız hesabınıza otomatik yansıtılacaktır',
  images: [
      { src: '/image/path-to-image1.jpg', description: 'Her Çarşamba 00:01 de yıldız sayınıza göre hesabınıza çevrim şartsız nakit ödül tanımlanır' },
      { src: '/image/path-to-image2.jpg', description: 'Yıldız sayınıza bağlı olarak Poseidon dan aldığınız kuponlara daha az komisyon ödersiniz' },
      { src: '/image/path-to-image3.jpg', description: 'Her Cuma 18:00 da çekilen çekilişte yıldız sayınız kadar çekilişe katılmaya hak kazanır,birbirinden özel hediyeler alırsınız' },
      { src: '/image/path-to-image4.jpg', description: 'Yıldız sayınıza bağlı olarak Zeus dan olası bir kayıp yaşarsanız daha fazla discount(iade) alırsınız' }
  ]
},
{
  title: 'Neden Usd-T (Tether) kullanmalıyım?',
  description: 'Size gönderilen para dünyanın neresinde olursanız olsun dakikalar içinde ulaşır ve bankalar arası yüksek komisyon bedelleri ödemezsiniz .',
  images: [
      { src: '/image/deposit1.jpg', description: 'Ayrıca Usd-T kullanmak yıldız yarışında 1 yıldız kazandırır.' },
      { src: '/image/deposit2.jpg', description: '0 Komisyon ile yatırım ve çekimlerinizi yapabilmenizi sağlar' }
  ]
},
{
  title: 'Ödeme Yöntemleri',
  description: 'Para yatırmak için kredi kartı, banka transferi veya diğer ödeme yöntemlerini kullanabilirsiniz. İlgili ödeme yöntemi simgelerine tıklayarak detaylı bilgi alabilir ve işlemlere başlayabilirsiniz.',
  images: [
      { src: '/image/path-to-image3.jpg', description: 'Görsel 3 açıklaması' },
      { src: '/image/path-to-image4.jpg', description: 'Görsel 4 açıklaması' }
  ]
}
];

const Tutorial = ({ tutorialopen, setTutorialOpen, tutorialid, setTutorialid }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    console.log("Tutorial component mounted");
    const tutorialSeen = Cookies.get('tutorial') === 'false';
    if (tutorialSeen) {
      setTutorialOpen(false);
    }
  }, [setTutorialOpen]);

  useEffect(() => {
    console.log("Current tutorial id:", tutorialid);
    setLoading(true);
    if(tutorialData[tutorialid]?.images){
      preloadImages(tutorialData[tutorialid].images);
    }
  }, [tutorialid]);

  const handleClose = () => {
    setTutorialOpen(false);
    Cookies.set('loginfirst', '2', { expires: 365 });
    if (dontShowAgain) {
      Cookies.set('tutorial', '2', { expires: 365 });
    }
  };

  const handleClose2 = () => {
    setTutorialOpen(false);
    Cookies.set('loginfirst', '2', { expires: 365 });
      Cookies.set('tutorial', '2', { expires: 365 });
  
  };


  const handleDontShowAgainChange = (event) => {
    console.log("DontShowAgain checkbox changed", event.target.checked);
    setDontShowAgain(event.target.checked);
  };

  const preloadImages = (images) => {
    let loadedImages = 0;
    images.forEach(image => {
      const img = new Image();
      img.src = image.src;
      img.onload = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          setLoading(false);
        }
      };
      img.onerror = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          setLoading(false);
        }
      };
    });
  };

  const currentData = tutorialData[tutorialid];

  return (
    <Modal
      open={tutorialopen}
      onClose={handleClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          maxWidth: '900px',
          width: '90%',
          height: tutorialid==0?isMobile?'70%':"40%":'auto',
          maxHeight: '800px',
          overflowY: tutorialid==0?'hidden':'auto',
          overflowX: 'hidden',
          position: 'relative',
          backgroundColor: "#d79f09",
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px'
          }
        }}
      >
        <Grid container justifyContent={"space-between"} alignContent={"center"} sx={{ width: "97%" }}>
          <Grid item sx={{ width: "33%" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item sx={{ width: "25%" }}>
            <Typography variant="h6" component="h5" textAlign="center" sx={{ marginTop: "5px" }}>
              {"Öğretici"}
            </Typography>
          </Grid>
          <Grid item sx={{ width: "33%", textAlign: "end" }}>
          <FormControlLabel
  control={
    <Checkbox
      checked={dontShowAgain}
      onChange={handleDontShowAgainChange}
      name="dontShowAgain"
      sx={{
        '&.Mui-checked': {
          color: '#202504'
        }
      }}
    />
  }
  sx={{ marginLeft: "10px" }}
  label={
    <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
      Bir daha gösterme
    </Typography>
  }
/>
          </Grid>
        </Grid>
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            height:tutorialid==0?"100%":"auto",
            p: 4,
            position: 'relative'
          }}
        >
          {tutorialid==0?
          
          <Box textAlign="center">
          <Typography variant="h5" component="h5">
            {"Youbetshop'a Hoş Geldiniz"}
          </Typography>
          <Typography >
            {"Sıradışı bir bahis deneyimi yaşamaya hazır mısınız?.Bahis sistemimiz hakkında detaylı bilgi almak ve ayrıcalıklı yıldız yarışı programımız ile fırsatları kaçırmamak için öğretici ile devam edebilir,tecrübe kazanabilirsiniz.Aklınıza takılan tüm sorularınız için destek sayfasından bize ulaşabilir,forum sayfamızda konu başlıklarına göz atabilirsiniz."}
          </Typography>
          <Box mt={2} display="flex" justifyContent="center" gap={2}>
            <Button
              variant="contained"
              color="success"
              onClick={()=>{setTutorialid(1)}}
              style={{ width: '150px', height: isMobile?"100px":'150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',fontSize:isMobile&&"0.6rem" }}
            >
              <PlayArrowIcon style={{ fontSize: isMobile?"24px":'48px' }} />
              Öğreticiye Devam Et
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={()=>{handleClose}}
              style={{ width: '150px', height: isMobile?"100px":'150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',fontSize:isMobile&&"0.6rem" }}
            >
              <SkipNextIcon style={{ fontSize: isMobile?"24px":'48px'  }} />
              Öğreticiyi Şimdilik Geç
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={()=>{handleClose2()}}
              style={{ width: '150px', height: isMobile?"100px":'150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',fontSize:isMobile&&"0.6rem" }}
            >
              <BlockIcon style={{ fontSize: isMobile?"24px":'48px'  }} />
              Bir Daha Gösterme
            </Button>
          </Box>
        </Box>
        
        :
        <>
          <Typography variant="h5" component="h5" textAlign="center">
            {currentData.title}
          </Typography>
          <Typography sx={{ mt: 2, textAlign: 'center' }}>
            {currentData.description}
          </Typography>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {currentData.images.map((image, index) => (
                <Grid item xs={12} md={12} sm={6} lg={6} key={index}>
                  <Box textAlign="center">
                    <img
                      src={image.src}
                      alt={`Görsel ${index + 1}`}
                      style={{ width: '100%', borderRadius: 8, maxHeight: '300px', objectFit: 'cover' }}
                    />
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {image.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
               {tutorialid==1&&
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button variant="contained" color="primary" onClick={()=>{setTutorialid(2)}}>
              Yıldız nasıl kazandırır
            </Button>
            <Button variant="contained" color="primary" onClick={()=>{setTutorialid(4)}}>
            Yıldız Yarışı Nedir
            </Button>
            </Box>}
            {tutorialid==2&&
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(3)}}>
                      Yıldız Sayısı Nasıl Artar
                      </Button>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(4)}}>
                      Yıldız Yarışı Nedir
                      </Button>
                      </Box>
            }
                        {tutorialid==3&&
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(2)}}>
                      Yıldız nasıl kazandırır
                      </Button>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(6)}}>
                      Ödeme Yöntemleri
                      </Button>
                      </Box>
            }
                                    {tutorialid==4&&
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(3)}}>
                      Yıldız Ne Kazandırır
                      </Button>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(6)}}>
                      Ödeme Yöntemleri
                      </Button>
                      </Box>
            }
                   {
                   tutorialid==5&&
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(1)}}>
                      Poseidon Mantığı Nedir?
                      </Button>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(6)}}>
                      Ödeme Yöntemleri
                      </Button>
                      </Box>
            }
                   
                                    {
                                    tutorialid==6&&
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(5)}}>
                      Neden Usd-T (Tether) kullanmalıyım?
                      </Button>
                      <Button variant="contained" color="primary" onClick={()=>{setTutorialid(4)}}>
                      Yıldız Yarışı Nedir
                      </Button>
                      </Box>
            }
            </>
            }
        </Box>
      </Box>
    </Modal>
  );
};

export default Tutorial;
