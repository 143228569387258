import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import {
    AppBar, Toolbar, IconButton, Typography, Container, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Icon,useMediaQuery,useTheme,ToggleButtonGroup,ToggleButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import "dayjs/locale/tr";
import translatedata from '../translate.json';
const Mybets = ({selectedLanguage}) => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [couponData, setCouponData] = useState([]);
    const [dateFilter, setDateFilter] = useState([]);
    const [dataf, setDataf] = useState(null);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [statuscoup,setStatuscoup]= useState("0");
    const [totalBet, setTotalBet] = useState(0);
    const [totalWin, setTotalWin] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalWithdraw, setTotalWithdraw] = useState(0);
    const [last, setLast] = useState(0);
    const [profit, setTotalProfit] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleChangedate = (date) => {
        if (date[0] && date[1]) {
            const localDate0 = new Date(date[0].toLocaleString());
            const localDate1 = new Date(date[1].toLocaleString());
            localDate0.setDate(localDate0.getDate() + 1);
            localDate1.setDate(localDate1.getDate() + 1);
            setDateFilter([localDate0, localDate1]);
        }
    };

    const dateFormatted = (tarih) => {
        const dateObj = new Date(tarih);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear().toString();

        return `${year}-${month}-${day}`;
    };

    const formdate =(date)=>{
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
        return formattedDate;
    }
    useEffect(() => {
        let params;
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        let date1 = dateFilter[0];
        let date2 = dateFilter[1];

        if (!date1 && !date2) {
        // Mevcut tarih ve saat
        const now = new Date();
    
        // Ayın ilk günü saat 00:00:00
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0);
    
        // Bugünün son saati olan 23:59:59
        const endOfToday = new Date(now);
        endOfToday.setHours(23, 59, 59, 999);
    

            date1 = formdate(firstDayOfMonth);
            date2 = formdate(endOfToday);
        } else {
            date1 = dateFormatted(date1) + 'T00:00:00.000Z';
            date2 = dateFormatted(date2) + 'T23:59:59.999Z';
        }
        if (date1 && date2) {
            params = { date1: date1, date2: date2 };
        }

        axios
            .post("https://api.youbetshop.net/api/transaction/transaction-report", params, config)
            .then(function (response) {
                setDataf(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [dateFilter]);
    
    useEffect(() => {
        if(statuscoup=="1"){
            const data=dataf.filter((item)=>item.type_id==2 || item.type_id==3 || item.type_id==5 || item.type_id==6 || item.type_id==4 || item.type_id==11)
            setCouponData(data)
        }else if(statuscoup=="2"){
            const data=dataf.filter((item)=>item.type_id==1 || item.type_id==10 ||item.type_id==14 || item.type_id==8 || item.type_id==9 )
            setCouponData(data)
        }else{
            setCouponData(dataf)
        }
        
    }, [statuscoup,dataf])
    
    useEffect(() => {
        const totalbet = dataf
      ?.filter(obj => obj.type_id === 3 || obj.type_id === 7)
      .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalBet(totalbet?.toFixed(2));

         const totalwin = dataf
         ?.filter(obj => obj.type_id === 2 || obj.type_id === 4 || obj.type_id ===6)
         .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalWin(totalwin?.toFixed(2));
         setTotalProfit((totalwin-totalbet)?.toFixed(2))
         const totalfees = dataf
         ?.filter(obj => obj.type_id === 11)
         .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalFee(totalfees?.toFixed(2));
         setTotalProfit((totalwin-totalbet-totalfees)?.toFixed(2))
         const totaldeposit = dataf
         ?.filter(obj => obj.type_id === 1 || obj.type_id === 8  )
         .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalWin(totalwin?.toFixed(2));
         setTotalProfit((totalwin-totalbet-totalfees)?.toFixed(2))
         setTotalDeposit(totaldeposit?.toFixed(2))
         const totalwithdraw = dataf
         ?.filter(obj => obj.type_id === 10 || obj.type_id === 9)
         .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalWin(totalwin?.toFixed(2));
         setTotalProfit((totalwin-totalbet-totalfees)?.toFixed(2))
         setTotalWithdraw(totalwithdraw?.toFixed(2))
         setLast((totaldeposit-totalwithdraw)?.toFixed(2))

    }, [dataf])
    

    const getTextColor = (typeId) => {
        if (typeId === 1 ||typeId === 2 || typeId === 4 || typeId === 5 || typeId === 6 || typeId === 8) {
          return "rgb(39, 139, 0)";
        } else {
          return "rgb(203, 0, 0)";
        }
      };
      const getPreText = (typeId) => {
        if (typeId === 1 ||typeId === 2 || typeId === 4 || typeId === 5 || typeId === 6 || typeId === 8) {
          return "+";
        } else {
          return "-";
        }
      };
    const handleChanges = (e) => {
        setStatuscoup(e.target.value)
    };
    function formatDate(isoString) {
        // Tarih nesnesi oluştur ve UTC saat dilimine göre bilgileri al
        const date = new Date(isoString);
        
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Aylar 0-11 arası olduğu için +1 ekliyoruz
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        
        // İstenen biçimde birleştir
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">
                        {translatedata[selectedLanguage].transaction.home}
                    </Typography>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                    </Typography>
                    <Typography variant="h6" >
                    {translatedata[selectedLanguage].transaction.safe}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                <Box my={4} sx={{ backgroundColor: "#ededed", borderRadius: "1rem", padding: "15px" }}>
                    <Grid container>
                        <Grid item xs={12} sx={{  paddingTop: "20px" }}>
                            <Typography variant="h4" sx={{ color: "#333" }}>
                            {translatedata[selectedLanguage].transaction.safe}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sm={3} lg={3} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ m: 1 }} adapterLocale="tr">
                                <DateRangePicker
                                    sx={{ m: 1, minWidth: '15%' }}
                                    label={dateFilter[0] && dateFilter[1] ? `${dateFormatted(dateFilter[0])} - ${dateFormatted(dateFilter[1])}` : translatedata[selectedLanguage].transaction.filterdate}
                                    slots={{ field: SingleInputDateRangeField }}
                                    onChange={handleChangedate}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} >
                                <ToggleButtonGroup
                                    color="primary"
                                    value={statuscoup}
                                    exclusive
                                    fullWidth={isMobile}
                                    onChange={handleChanges}
                                    aria-label="Platform"
                                    sx={{ m: 1 }}
                                >
                                    <ToggleButton value={"0"} size={isMobile ? "small" : "medium"}>{translatedata[selectedLanguage].transaction.all}</ToggleButton>
                                    <ToggleButton value={"1"} size={isMobile ? "small" : "medium"}>{translatedata[selectedLanguage].transaction.betprocess}</ToggleButton>
                                    <ToggleButton value={"2"} size={isMobile ? "small" : "medium"}>{translatedata[selectedLanguage].transaction.financialprocess}</ToggleButton>
                                </ToggleButtonGroup>
                        </Grid>
                        {statuscoup==1?
                        <Grid item xs={12} md={12} sm={12} lg={12} sx={{marginLeft:"5px"}}>
                            <Grid container justifyContent={"center"}  alignItems={"center"}>
                            <Grid item xs={2.7} md={2.7} sm={2.8} lg={2.8} m={0.5} sx={{padding:"5px",backgroundColor:"#c1fcd6",borderRadius:"5px"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].transaction.totalprofit}</span><br/>{totalWin}</Typography>
                            </Grid>
                            <Grid item xs={2.7} md={2.7} sm={2.8} lg={2.8} m={0.5} sx={{padding:"5px",backgroundColor:"#faa8a8",borderRadius:"5px"}}>
                                <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].transaction.totallose}</span><br/>{totalBet}</Typography>
                            </Grid>
                            <Grid item xs={2.7} md={2.7} sm={2.8} lg={2.8} m={0.5} sx={{padding:"5px",backgroundColor:"#faa8a8",borderRadius:"5px"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].transaction.totalfee}</span><br/>{totalFee}</Typography>
                            </Grid>
                            <Grid item xs={2.7} md={2.7} sm={2.8} lg={2.8} m={0.5} sx={{padding:"5px",backgroundColor:profit<0?"#faa8a8":"#c1fcd6",borderRadius:"5px"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].transaction.profit}</span><br/>{profit}</Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        :
                        statuscoup==2?
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Grid container justifyContent={"center"}  alignItems={"center"}>
                            <Grid item xs={3.6} md={3.6} sm={3.6} lg={3.6} m={0.5} sx={{padding:"5px",backgroundColor:"#c1fcd6",borderRadius:"5px"}}>
                                <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].transaction.totaldeposit}</span><br/>{totalDeposit}</Typography>
                            </Grid>
                            <Grid item xs={3.6} md={3.6} sm={3.6} lg={3.6} m={0.5} sx={{padding:"5px",borderRadius:"5px",backgroundColor:"#faa8a8"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].transaction.totalwithdraw}</span><br/>{totalWithdraw}</Typography>
                            </Grid>
                            <Grid item xs={3.6} md={3.6} sm={3.6} lg={3.6} m={0.5} sx={{padding:"5px",backgroundColor:last<0?"#faa8a8":"#c1fcd6",borderRadius:"5px"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].transaction.end}</span><br/>{last}</Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        :""
                        }
                        <Grid item xs={12}>
                            <TableRow />
                            <TableContainer component={Paper} sx={{ backgroundColor: "#ededed" }}>
                                <Table>
                                    <TableHead sx={{ backgroundColor: "#ece4d4" }}>
                                        <TableRow>

                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].transaction.id}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].transaction.type}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].transaction.amount}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].transaction.lastamount}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].transaction.operator}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem",minWidth:"70px"  }}>{translatedata[selectedLanguage].transaction.date}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {couponData?.map((coupon, index) => (
                                            <>
                                                <TableRow key={coupon.coupon_id} onMouseEnter={() => setHoveredRow(index)}
                                                    onMouseLeave={() => setHoveredRow(null)}
                                                    sx={{
                                                        borderLeft:"solid 3px",
                                                        borderColor:getTextColor(coupon.type_id),
                                                        backgroundColor: hoveredRow === index ? '#f0f0f0' : (index % 2 === 0 ? '#ededed' : '#ece4d4'),
                                                        height: hoveredRow === index ? '60px' : '50px', // Satırın boyutunu burada ayarlayabilirsiniz
                                                        transition: 'height 0.3s ease', // Boyut değişimini düzgünleştirmek için geçiş efekti ekleyin
                                                    }}>
                                                    <TableCell sx={{ color:getTextColor(coupon.type_id),fontSize:isMobile&&"0.65rem" ,padding:"6px"  }}>
                                                       {coupon.id}
                                                    </TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.type}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem",color:getTextColor(coupon.type_id) }}>{getPreText(coupon.type_id)+coupon.amount}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.last_amount}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.op_id}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem",minWidth:"70px"  }}>{formatDate(coupon.transaction_date)}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default Mybets;
