import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './component/header';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles  } from '@mui/material';
import CharacterGrid from './component/mainpage';
import Poseidon from './pages/poseidon';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Mybets from './pages/my-bets';
import Ares from './pages/ares';
import Deposit from './pages/deposit';
import Withdraw from './pages/withdraw';
import Mailverify from './pages/mailverify';
import Transactions from './pages/transactions';
import Help from './pages/help';
import ResetPasswordForm from './pages/resetpass';
import WithdrawReq from './pages/withdrawreq';
import DepositReq from './pages/depositreq';
import ComingSoonZeus from './pages/zeus';
import Telegram from './pages/telegram';
import Withdrawapprove from './pages/withdrawapprove';
import Starrace from './pages/star-race';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d79f09', // Replace with your desired primary color
    },
  },
});

const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['serviceToken']);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Giriş durumu, bu değeri değiştirerek test edebilirsiniz.
  const [username, setUsername] = useState("Zeus Doe");
  const [balance, setBalance] = useState(0); 
  const [newbalance, setNewBalance] = useState(0); 
  const [blockedbalance, setNewBlockedBalance] = useState(0); 
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [blockbalance,setBlockBalance]= useState(0);
  const [verifystatus, setVerifyStatus] = useState(false);
  const [aresfee,setAresFee]= useState(0);
  const [poseidonfee,setPoseidonFee]= useState(0);
  const [zeusfee,setZeusFee]= useState(0);
  const [notify,setNotify]= useState(0);
  const [promocodes, setPromocode] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  useEffect(() => {
    // Belirli z-index değerine sahip <div> öğelerini Seçenek
    const removeSpecificDiv = () => {
      const divs = document.querySelectorAll('div');
      divs.forEach(div => {
        const style = div.getAttribute('style');
        if (style && style.includes('z-index: 100000;')) {
          div.remove();
        }
      });
    };
 
    // removeSpecificDiv fonksiyonunu 500ms'de bir çalıştır
    const intervalId = setInterval(removeSpecificDiv, 100);
  
    // Bileşen Seçenekıldığında interval'i temizle
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
useEffect(() => {
  if(cookies.lang){
    setSelectedLanguage(cookies.lang)
  }

}, [])

  useEffect(() => {
    // URLSearchParams ile URL'deki parametreleri al
    const params = new URLSearchParams(window.location.search);
    const promo = params.get('promocode');

    if (promo) {
      setPromocode(promo);
    }
  }, []);
  const recheck =()=>{
    if (cookies.serviceToken) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cookies.serviceToken) {
        config.headers['Authorization'] = `Bearer ${cookies.serviceToken}`;
      }

      axios.post("https://api.youbetshop.net/verify", { token: cookies.serviceToken }, config)
        .then(response => {
          if (response) {
            if (response.data.success === true) {
              setIsLoggedIn(true);
              setBalance(response.data.data.balance);
              setNewBalance(response.data.data.balance);
              setAresFee(response.data.data.ares_fee)
              setPoseidonFee(response.data.data.poseidon_fee)
              setZeusFee(response.data.data.zeus_fee)
              setNewBlockedBalance(response.data.data.block_balance);
              setBlockBalance(response.data.data.block_balance);
              setVerifyStatus(response.data.data.verification_status)
              setNotify(response.data.data.ticket_admin_res)
              setUsername(cookies.email);
              setIsLoggedIn(true);
            } else {
              removeCookie("email");
              removeCookie("serviceToken");
            }
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          removeCookie("email");
          removeCookie("serviceToken");
        });
    }else{
      removeCookie("email");
              removeCookie("serviceToken");
    }
  }
  useEffect(() => {
    if (cookies.serviceToken) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cookies.serviceToken) {
        config.headers['Authorization'] = `Bearer ${cookies.serviceToken}`;
      }

      axios.post("https://api.youbetshop.net/verify", { token: cookies.serviceToken }, config)
        .then(response => {
          if (response) {
            if (response.data.success === true) {
              setIsLoggedIn(true);
              setBalance(response.data.data.balance);
              setBlockBalance(response.data.data.block_balance);
              setNewBlockedBalance(response.data.data.block_balance);
              setNewBalance(response.data.data.balance);
              setVerifyStatus(response.data.data.verification_status);
              setAresFee(response.data.data.ares_fee)
              setPoseidonFee(response.data.data.poseidon_fee)
              setPoseidonFee(response.data.data.poseidon_fee)
              setZeusFee(response.data.data.zeus_fee)
              setNotify(response.data.data.ticket_admin_res)
              setUsername(cookies.email);
              setIsLoggedIn(true);
            } else {
              removeCookie("email");
              removeCookie("serviceToken");
            }
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          removeCookie("email");
          removeCookie("serviceToken");
        });
    }else{
      removeCookie("email");
              removeCookie("serviceToken");
    }
  }, [cookies, removeCookie]);
 
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundImage: 'url(/image/bg.webp)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
          },
        }}
      />
      <Router>
        <Header isLoggedIn={isLoggedIn} notify={notify} setSelectedLanguage={setSelectedLanguage} selectedLanguage={selectedLanguage} setNotify={setNotify} promocodes={promocodes} isLoginOpen={isLoginOpen} setLoginOpen={setLoginOpen} balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} username={username} />
        <Routes>
          <Route path="/" element={<CharacterGrid setLoginOpen={setLoginOpen}  selectedLanguage={selectedLanguage} isLoggedIn={isLoggedIn} aresfee={aresfee} poseidonfee={poseidonfee}  zeusfee={zeusfee} />} />
          <Route path="/poseidon" element={<Poseidon recheck={recheck} newbalance={newbalance} isLoggedIn={isLoggedIn} selectedLanguage={selectedLanguage} blockedbalance={blockedbalance}  poseidonfee={poseidonfee}  balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} />} />
          <Route path="/ares" element={<Ares recheck={recheck} newbalance={newbalance} selectedLanguage={selectedLanguage} blockedbalance={blockedbalance} aresfee={aresfee}  balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} />} />
          <Route path="/my-bets" element={<Mybets selectedLanguage={selectedLanguage}/>} />
          <Route path="/zeus" element={<ComingSoonZeus selectedLanguage={selectedLanguage} />} />
          <Route path="/verify-mail" element={<Mailverify selectedLanguage={selectedLanguage}/>} />
          <Route path="/deposit" element={<Deposit recheck={recheck} isLoggedIn={isLoggedIn}  selectedLanguage={selectedLanguage}/>} />
          <Route path="/withdraw" element={<Withdraw recheck={recheck} verifystatus={verifystatus} selectedLanguage={selectedLanguage}/>} />
          <Route path="/transactions" element={<Transactions recheck={recheck} selectedLanguage={selectedLanguage}/>} />
          <Route path="/help" element={<Help recheck={recheck} selectedLanguage={selectedLanguage}/>} />
          <Route path="/reset-password" element={<ResetPasswordForm recheck={recheck} selectedLanguage={selectedLanguage}/>} />
          <Route path="/withdrawreq" element={<WithdrawReq recheck={recheck} selectedLanguage={selectedLanguage}/>} />
          <Route path="/depositreq" element={<DepositReq recheck={recheck} selectedLanguage={selectedLanguage}/>} />
          <Route path="/telegram" element={<Telegram selectedLanguage={selectedLanguage}/>} />
          <Route path="/star-race" element={<Starrace isLoggedIn={isLoggedIn} selectedLanguage={selectedLanguage}/>} />
          <Route path="/approve-withdraw" element={<Withdrawapprove isLoggedIn={isLoggedIn} selectedLanguage={selectedLanguage} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
