import React, { useState, useEffect } from 'react';
import { Grid, Paper, AppBar, Toolbar, Box, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Typography, ToggleButtonGroup, OutlinedInput, InputAdornment, ToggleButton, IconButton, Snackbar, Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Loading from '../component/loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Logo from '../media/logosvg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';
import 'dayjs/locale/tr';
import { useNavigate } from 'react-router-dom';
import translatedata from '../translate.json';

const MyTransactions = ({ recheck, verifystatus, selectedLanguage }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [opensnack, setOpenSnack] = useState(false);
    const [status, setStatus] = useState("error");
    const [statustext, setStatustext] = useState("");
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1280 && window.innerWidth < 1800);
    const [datas, setFiltredDatas] = useState([]);
    const [statuscoup, setStatuscoup] = useState("all");
    const [profit, setProfit] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);
    const [amounttry, setAmountTry] = useState();
    const [accountnum, setAccountNum] = useState();
    const [methodid, setMethodId] = useState();
    const [methodname, setMethodName] = useState();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [openMailDialog, setOpeMailnDialog] = useState(false);
    const [couponToDelete, setCouponToDelete] = useState(null);
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [isRequestLoading, setIsRequestLoading] = useState(false); // Yeni loading state

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1280);
            setIsMediumScreen(window.innerWidth > 1280 && window.innerWidth < 1800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        setLoading(true);
        axios.get("https://api.youbetshop.net/payment-methods")
            .then(response => {
                if (response?.data?.data) {
                    const method = response?.data?.data?.filter((item) => item?.method_type == "2")
                    setData(method);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [dateRange]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const handleChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(columnId);
    };

    const filteredData = datas?.filter(item =>
        Object.values(item).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const sortedData = filteredData.sort((a, b) => {
        if (order === 'desc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
        } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
        }
    });

    useEffect(() => {
        if (statuscoup == 2) {
            setFiltredDatas(data.filter(item => item.type_id == 8 || item.type_id == 1))
        } else if (statuscoup == 3) {
            setFiltredDatas(data.filter(item => item.type_id == 9 || item.type_id == 10))
        } else if (statuscoup == 4) {
            setFiltredDatas(data.filter(item => item.type_id == 14))
        } else if (statuscoup == 5) {
            setFiltredDatas(data.filter(item => item.type_id == 2 || item.type_id === 3 || item.type_id === 4))
            const total2And4 = data
                .filter(obj => obj.type_id === 2 || obj.type_id === 4)
                .reduce((sum, obj) => sum + Number(obj.amount), 0);

            const total3And14 = data
                .filter(obj => obj.type_id === 3 || obj.type_id === 14)
                .reduce((sum, obj) => sum + Number(obj.amount), 0);

            const difference = total2And4 - total3And14;
            setProfit(difference.toFixed(2))
        } else {
            setFiltredDatas(data)
        }
    }, [statuscoup, data])

    const handleChanges = (event, newAlignment) => {
        setStatuscoup(newAlignment);
    };

    const handleOpenDialog = (coupon_id) => {
        setCouponToDelete(coupon_id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCouponToDelete(null);
    };

    const handleOpenMailDialog = () => {
        setOpeMailnDialog(true);
        sendmail();
    };

    const handleCloseMailDialog = () => {
        setOpeMailnDialog(false);
    };

    const handleConfirmDelete = () => {
        deleteCoup(couponToDelete);
        handleCloseDialog();
    };

    const sendmail = () => {
        const token = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios.post("https://api.youbetshop.net/api/user/verifymail", "", config)
            .then(response => {
                if (response?.data?.success == true) {
                    setOpenSnack(true);
                    setStatus("success");
                    setStatustext(translatedata[selectedLanguage].withdraw.success);
                } else {
                    setOpenSnack(true);
                    setStatus("error");
                    setStatustext(response?.data?.data?.message);
                }
            }).catch(error => {
                console.error('Error with API requests:', error);
                setOpenSnack(true);
                setStatus("error");
                setStatustext(translatedata[selectedLanguage].withdraw.error);
            });
    }

    const handleDateChange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    const [expandedRows, setExpandedRows] = useState({});
    useEffect(() => {
        const removeSpecificDiv = () => {
            const divs = document.querySelectorAll('div');
            divs.forEach(div => {
                const style = div.getAttribute('style');
                if (style && style.includes('z-index: 100000;')) {
                    div.remove();
                }
            });
        };

        const intervalId = setInterval(removeSpecificDiv, 500);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const toggleExpand = (coupon_id) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [coupon_id]: !prevExpandedRows[coupon_id]
        }));
    };

    const opendia = (status, name, id) => {
        setOpenDialog(status)
        setMethodName(name)
        setMethodId(id)
    }

    const gopay = () => {
        const token = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        if (accountnum && amounttry) {
            setIsRequestLoading(true); // İstek başladığında loading başlat
            let params = { method_id: methodid, amount: amounttry, wallet_code: accountnum };
            axios.post("https://api.youbetshop.net/api/payment/withdraw-payment", params, config)
                .then(response => {
                    if (response?.data?.data?.success == true) {
                        handleCloseDialog();
                        setOpenSnack(true);
                        setStatus("success");
                        recheck();
                        setStatustext(translatedata[selectedLanguage].withdraw.success2);
                    } else {
                        setOpenSnack(true);
                        setStatus("error");
                        setStatustext(response?.data?.data?.message);
                    }
                    setIsRequestLoading(false); // İstek tamamlandığında loading durdur
                }).catch(error => {
                    console.error('Error with API requests:', error);
                    setOpenSnack(true);
                    setStatus("error");
                    setStatustext(translatedata[selectedLanguage].withdraw.error);
                    setIsRequestLoading(false); // İstek başarısız olduğunda loading durdur
                });
        } else {
            setOpenSnack(true);
            setStatus("error");
            setStatustext(translatedata[selectedLanguage].withdraw.error);
        }
    }



    const gowithdrawreq = () => {
        navigate(`/withdrawreq`);
    }

    dayjs.locale('tr')

    return (
        <> <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
                    <ArrowBackIcon />
                    <Typography variant="h6" >
                        {translatedata[selectedLanguage].withdraw.home}
                    </Typography>
                </IconButton>
                <Typography variant="h6" style={{ flexGrow: 1 }}>

                </Typography>
                <Typography variant="body1" style={{ marginRight: '10px' }}>

                </Typography>
            </Toolbar>
        </AppBar>
            <Grid container justifyContent="center" alignItems="center" sx={{ paddingTop: "1%" }}>
                <Grid item xs={11} sm={11} md={8} lg={8}>
                    <Typography variant='h4' sx={{ fontWeight: "200", fontSize: isSmallScreen ? "2rem" : "3rem", color: "#d79f09",backgroundColor:"#2d2d2db8" }} >{translatedata[selectedLanguage].withdraw.withdraw}</Typography>
                    {/* {verifystatus == 0 &&
                        <Alert
                            color="error"
                            variant="filled"
                            sx={{ width: '100%', color: "#ece4d4" }}
                        >
                            <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                                <Grid item sx={{marginRight:"10px"}}>
                                    <Typography>Hesabınızı doğrulamadan çekim yapamazsınız.</Typography>
                                </Grid>
                                <Grid item >
                                    <Button variant="contained" color="success" onClick={handleOpenMailDialog}>Doğrula</Button>
                                </Grid>
                            </Grid>
                        </Alert>

                    } */}
                    <Paper elevation={3} style={{ padding: '20px', borderBottom: "solid #d79f09" }}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
      <Grid item xs={6} sm={6} md={6} lg={8}>
        <div style={{ marginRight: isMediumScreen ? '45px' : '0', color: '#d79f09', fontWeight: 800 }}>
          {translatedata[selectedLanguage].withdraw.methods}
        </div>
      </Grid>
      <Grid item>
        <Button variant='outlined' onClick={gowithdrawreq} sx={{padding:"5px 10px"}}>{translatedata[selectedLanguage].withdraw.withdrawreq}</Button>
      </Grid>
    </Grid>

                    </Paper>
                    <Paper elevation={3} style={{ marginBottom: '20px' }}>
                        <Grid container sx={{ padding: "20px", alignItems: "center" }}>
                            {data.map((item) => (
                                <>
                                    <Grid
                                        key={item.id}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            textAlign: "center",
                                            border: "solid 2px #d79f09",
                                            borderRadius: "5px",
                                            transition: "transform 0.3s, box-shadow 0.3s",
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                            '&:hover': {
                                                transform: "scale(1.05)",
                                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                                                border: "solid 2px #d79f09",
                                                borderRadius: "5px"
                                            }
                                        }}
                                        item
                                        xs={3.8}
                                        sm={3.8}
                                        md={2.4}
                                        lg={2.4}
                                        onClick={() => opendia(true, item.method_name, item.id, item.method_endpoint)}
                                    >
                                        <img
                                            src={`/image/${item.method_name}.png`}
                                            alt={item.method_name}
                                            style={{ width: '50%', height: 'auto', marginBottom: '8px', marginTop: "10px" }}
                                        />
                                        <div>
                                            {item.method_name}
                                        </div>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    color={status}
                    variant="filled"
                    sx={{ width: '100%', color: "#ece4d4" }}
                >
                    {statustext}
                </Alert>
            </Snackbar>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {methodname + translatedata[selectedLanguage].withdraw.withdrawwith}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography sx={{ mb: 2, textAlign: 'start', margin: "0px" }}>{translatedata[selectedLanguage].withdraw.withdrawamount}</Typography>
                        <OutlinedInput id="outlined-basic" sx={{ width: "100%", marginBottom: "10px" }} type='number' onChange={(e) => setAmountTry(e.target.value)} value={amounttry} endAdornment={<InputAdornment position="end">{translatedata[selectedLanguage].withdraw.currency}</InputAdornment>} variant="outlined" />
                        <Typography sx={{ mb: 2, textAlign: 'start', margin: "0px" }}>{translatedata[selectedLanguage].withdraw.accountno}</Typography>
                        <OutlinedInput id="outlined-basic" sx={{ width: "100%", marginBottom: "10px" }} onChange={(e) => setAccountNum(e.target.value)} value={accountnum} variant="outlined" />

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {translatedata[selectedLanguage].withdraw.cancel}
                    </Button>
                    <Button onClick={gopay} variant="contained" color="primary" autoFocus disabled={isRequestLoading}>
                        {isRequestLoading ? <CircularProgress size={24} /> : translatedata[selectedLanguage].withdraw.withdrawmak}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openMailDialog}
                onClose={handleCloseMailDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Mail Doğrulama"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography sx={{ mb: 2, textAlign: 'start', margin: "0px" }}>{translatedata[selectedLanguage].withdraw.text}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMailDialog} color="primary">
                    {translatedata[selectedLanguage].withdraw.close}
                    </Button>
                    <Button onClick={sendmail} variant="contained"  color="primary">
                    {translatedata[selectedLanguage].withdraw.sendagain}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MyTransactions;
