import React, { useState, useEffect } from 'react';
import { Grid, Paper, AppBar, Toolbar, Typography, IconButton, Snackbar, Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, OutlinedInput, InputAdornment } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import translatedata from '../translate.json';
import Tutorial from '../component/Tutorial';
import Cookies from 'js-cookie';
const MyTransactions = ({selectedLanguage,isLoggedIn}) => {
    const [data, setData] = useState([]);
    const [opensnack, setOpenSnack] = useState(false);
    const [status, setStatus] = useState("error");
    const [statustext, setStatustext] = useState("");
    const [amounttry, setAmountTry] = useState();
    const [methodid, setMethodId] = useState();
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1280 && window.innerWidth < 1800);
    const [methodname, setMethodName] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [tutorialid,setTutorialid]=useState(6);
    const [tutorialopen,setTutorialOpen]=useState(false);
    const navigate = useNavigate();
    const [cookies] = useCookies(['serviceToken']);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1280);
            setIsMediumScreen(window.innerWidth > 1280 && window.innerWidth < 1800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
          console.log("User is logged in.");
          const tutorial = Cookies.get('tutorial');
          console.log("Tutorial cookie:", tutorial);
          if (tutorial !== "2") {
            setTutorialOpen(true);
            setTutorialid(6);
          }
        }
      }, [isLoggedIn]);
    useEffect(() => {
        axios.get("https://api.youbetshop.net/payment-methods")
            .then(response => {
                if (response?.data?.data) {
                    const method = response.data.data.filter((item) => item?.method_type == "1");
                    setData(method);
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const handleOpenDialog = (status, name, id) => {
        setOpenDialog(status);
        setMethodName(name);
        setMethodId(id);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const gopay = () => {
        const token = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const { hostname } = window.location;
        let params = { method_id: methodid, amount: amounttry, backurl: hostname };

        // Yeni sekmeyi açmak için önce bir referans oluşturun
        const newWindow = window.open('', '_blank');
        axios.post("https://api.youbetshop.net/api/payment/create-wallet", params, config)
            .then(response => {
                if (response?.data?.data?.url) {
                    // URL'yi yeni sekmeye ayarlayın
                    newWindow.location.href = response.data.data.url;
                    handleCloseDialog();
                } else {
                    newWindow.close(); // Hata durumunda yeni sekmeyi kapatın
                    setOpenSnack(true);
                    setStatus("error");
                    setStatustext(translatedata[selectedLanguage].notify.error);
                }
            }).catch(error => {
                newWindow.close(); // API hatası durumunda yeni sekmeyi kapatın
                console.error('Error with API requests:', error);
                setOpenSnack(true);
                setStatus("error");
                setStatustext(translatedata[selectedLanguage].notify.error);
            });
    };
    const gowithdrawreq = () => {
        navigate(`/depositreq`);
    }
    return (
        <>        
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                        <Typography variant="h6">{translatedata[selectedLanguage].deposit.home}</Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid container justifyContent="center" alignItems="center" sx={{ paddingTop: "1%" }}>
                <Grid item xs={11} sm={11} md={8} lg={8}>
                    <Typography variant='h4' sx={{ fontWeight: "200", fontSize: "3rem", color: "#333",color: "#d79f09",backgroundColor:"#2d2d2db8" }}>{translatedata[selectedLanguage].deposit.deposit}</Typography>
                    <Paper elevation={3} style={{ padding: '20px', borderBottom: "solid #d79f09" }}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
      <Grid item xs={6} sm={6} md={6} lg={8}>
        <div style={{ marginRight: isMediumScreen ? '45px' : '0', color: '#d79f09', fontWeight: 800 }}>
        {translatedata[selectedLanguage].deposit.methods}
        </div>
      </Grid>
      <Grid item>
        <Button variant='outlined' onClick={gowithdrawreq} sx={{padding:"5px 10px"}}>{translatedata[selectedLanguage].deposit.depositreq}</Button>
      </Grid>
    </Grid>
                    </Paper>
                    <Paper elevation={3} style={{ marginBottom: '20px' }}>
                        <Grid container sx={{ padding: "20px", alignItems: "center" }}>
                            {data?.map((item) => (
                                <Grid
                                    key={item.id}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center",
                                        border: "solid 2px #d79f09",
                                        borderRadius: "5px",
                                        transition: "transform 0.3s, box-shadow 0.3s",
                                        cursor: "pointer",
                                        marginLeft: "5px",
                                        '&:hover': {
                                            transform: "scale(1.05)",
                                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                                            border: "solid 2px #d79f09",
                                            borderRadius: "5px"
                                        }
                                    }}
                                    item
                                    xs={3.8}
                                    sm={3.8}
                                    md={2.4}
                                    lg={2.4}
                                    onClick={() => handleOpenDialog(true, item.method_name, item.id)}
                                >
                                    <img
                                        src={`/image/${item.method_name}.png`}
                                        alt={item.method_name}
                                        style={{ width: '50%', height: 'auto', marginBottom: '8px', marginTop: "10px" }}
                                    />
                                    <div>{item.method_name}</div>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    color={status}
                    variant="filled"
                    sx={{ width: '100%', color: "#ece4d4" }}
                >
                    {statustext}
                </Alert>
            </Snackbar>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {methodname + translatedata[selectedLanguage].deposit.withdeposit}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography sx={{ mb: 2, textAlign: 'start', margin: "0px" }}>{translatedata[selectedLanguage].deposit.depositamount}</Typography>
                        <OutlinedInput id="outlined-basic" sx={{ width: "100%", marginBottom: "10px" }} type='number' onChange={(e) => setAmountTry(e.target.value)} value={amounttry} endAdornment={<InputAdornment position="end">{translatedata[selectedLanguage].deposit.currency}</InputAdornment>} variant="outlined" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                    {translatedata[selectedLanguage].deposit.cancel}
                    </Button>
                    <Button onClick={gopay} variant="contained" color="primary" autoFocus>
                    {translatedata[selectedLanguage].deposit.checkout}
                    </Button>
                </DialogActions>
            </Dialog>
            <Tutorial tutorialopen={tutorialopen} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid}/>
        </>
    );
};

export default MyTransactions;
