import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import {
    AppBar, Toolbar, IconButton, Typography, Container, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Icon,useMediaQuery,useTheme
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import "dayjs/locale/tr";
import translatedata from '../translate.json';

const Mybets = ({selectedLanguage}) => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [couponData, setCouponData] = useState([]);
    const [dateFilter, setDateFilter] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [hoveredRow, setHoveredRow] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleChangedate = (date) => {
        if (date[0] && date[1]) {
            const localDate0 = new Date(date[0].toLocaleString());
            const localDate1 = new Date(date[1].toLocaleString());
            localDate0.setDate(localDate0.getDate() + 1);
            localDate1.setDate(localDate1.getDate() + 1);
            setDateFilter([localDate0, localDate1]);
        }
    };

    const dateFormatted = (tarih) => {
        const dateObj = new Date(tarih);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear().toString();

        return `${year}-${month}-${day}`;
    };
    const formdate =(date)=>{
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
        return formattedDate;
    }
    useEffect(() => {
        let params;
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        let date1 = dateFilter[0];
        let date2 = dateFilter[1];

        if (!date1 && !date2) {
        // Mevcut tarih ve saat
        const now = new Date();
    
        // Ayın ilk günü saat 00:00:00
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0);
    
        // Bugünün son saati olan 23:59:59
        const endOfToday = new Date(now);
        endOfToday.setHours(23, 59, 59, 999);
    

            date1 = formdate(firstDayOfMonth);
            date2 = formdate(endOfToday);
        } else {
            date1 = dateFormatted(date1) + 'T00:00:00.000Z';
            date2 = dateFormatted(date2) + 'T23:59:59.999Z';
        }

        if (date1 && date2) {
            params = { date1: String(date1), date2: String(date2) };
        }

        axios
            .post("https://api.youbetshop.net/api/coupon/getMyCoupons", params, config)
            .then(function (response) {
                setCouponData(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [dateFilter]);

    const handleExpandRow = (couponId) => {
        setExpandedRow(expandedRow === couponId ? null : couponId);
    };

    function formatDate(isoString) {
        // Tarih nesnesi oluştur ve UTC saat dilimine göre bilgileri al
        const date = new Date(isoString);
        
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Aylar 0-11 arası olduğu için +1 ekliyoruz
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        
        // İstenen biçimde birleştir
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">
                    {translatedata[selectedLanguage].mybets.home}
                    </Typography>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                    </Typography>
                    <Typography variant="h6" >
                    {translatedata[selectedLanguage].mybets.mybets}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                <Box my={4} sx={{ backgroundColor: "#ededed", borderRadius: "1rem", padding: "15px" }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ marginLeft: !isMobile&&"20px", paddingTop: "20px" }}>
                            <Typography variant="h4" sx={{ color: "#333" }}>
                            {translatedata[selectedLanguage].mybets.mybets}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginLeft: !isMobile&&"10px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ m: 1, minWidth: '15%' }}  adapterLocale="tr">
                                <DateRangePicker
                                    sx={{ m: 1, minWidth: '15%' }}
                                    label={dateFilter[0] && dateFilter[1] ? `${dateFormatted(dateFilter[0])} - ${dateFormatted(dateFilter[1])}` : "Tarih Filtrele"}
                                    slots={{ field: SingleInputDateRangeField }}
                                    onChange={handleChangedate}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TableRow />
                            <TableContainer component={Paper} sx={{ backgroundColor: "#ededed" }}>
                                <Table>
                                    <TableHead sx={{ backgroundColor: "#ece4d4" }}>
                                        <TableRow>

                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].mybets.couponId}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" ,fontSize:isMobile&&"0.65rem"}}>{translatedata[selectedLanguage].mybets.character}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].mybets.type}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].mybets.odd}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].mybets.block}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" ,fontSize:isMobile&&"0.65rem"}}>{translatedata[selectedLanguage].mybets.profit}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem",minWidth:"70px" }}>{translatedata[selectedLanguage].mybets.date}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].mybets.status}</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {couponData.map((coupon, index) => (
                                            <>
                                                <TableRow key={coupon.coupon_id} onMouseEnter={() => setHoveredRow(index)}
                                                    onMouseLeave={() => setHoveredRow(null)}
                                                    sx={{
                                                        backgroundColor: hoveredRow === index ? '#f0f0f0' : (index % 2 === 0 ? '#ededed' : '#ece4d4'),
                                                        height: hoveredRow === index ? '60px' : '50px', // Satırın boyutunu burada ayarlayabilirsiniz
                                                        transition: 'height 0.3s ease', // Boyut değişimini düzgünleştirmek için geçiş efekti ekleyin
                                                    }}>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",fontSize:isMobile&&"0.65rem" ,padding:"6px" }}>                                                       <Box display="flex" alignItems="center">
                                                        <IconButton onClick={() => handleExpandRow(coupon.coupon_id)}>
                                                            {expandedRow === coupon.coupon_id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                        </IconButton>
                                                        {coupon.coupon_id}
                                                    </Box>
                                                    </TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.combine_count}{translatedata[selectedLanguage].mybets.combine}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.mode}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.odds}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.stake}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.profit}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem",minWidth:"70px" }}>{formatDate(coupon.createdAt)}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.status === "0" ? translatedata[selectedLanguage].mybets.waiting : coupon.status == "2" ? coupon.is_win == 1 ? translatedata[selectedLanguage].mybets.win : translatedata[selectedLanguage].mybets.lose : translatedata[selectedLanguage].mybets.cancel}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
                                                        <Collapse in={expandedRow === coupon.coupon_id} timeout="auto" unmountOnExit>
                                                            <Box >
                                                                <Typography variant="h6" gutterBottom component="div" sx={{fontSize:isMobile&&"0.65rem"}}>
                                                                {translatedata[selectedLanguage].mybets.coupondet}
                                                                </Typography>
                                                                {coupon.description
                                                                    .slice() 
                                                                    .sort((a, b) => new Date(a.event_date) - new Date(b.event_date))
                                                                    .map((desc, index) => (
                                                                        <>                                                <TableRow key={desc.event_id} onMouseEnter={() => setHoveredRow(index)}
                                                                        onMouseLeave={() => setHoveredRow(null)}
                                                                        sx={{
                                                                            backgroundColor: hoveredRow === index ? '#f0f0f0' : (index % 2 === 0 ? '#ededed' : '#ece4d4'),
  
                                                                        }}>
                                                                        <TableCell style={{padding:"6px",fontSize:isMobile&&"0.65rem"}}>{desc.event_id}</TableCell>
                                                                        <TableCell style={{minWidth:"200px",padding:"6px",fontSize:isMobile&&"0.65rem"}}>{desc.event_name}</TableCell>
                                                                        <TableCell style={{minWidth:"100px",padding:"6px",fontSize:isMobile&&"0.65rem"}}>{desc.market_name}</TableCell>
                                                                        <TableCell style={{minWidth:"100px",padding:"6px",fontSize:isMobile&&"0.65rem"}}>{desc.odd_name}</TableCell>
                                                                        <TableCell style={{padding:"6px",fontSize:isMobile&&"0.65rem"}}>{desc.odd}</TableCell>
                    
                                                                    </TableRow>
                                                                        {/* <Box key={index} sx={{ mb: 2 }}>
                                                                            <Typography variant="body1">
                                                                                <strong>Etkinlik:</strong> {desc.event_name}
                                                                            </Typography>
                                                                            <Typography variant="body1">
                                                                                <strong>Piyasa:</strong> {desc.market_name}
                                                                            </Typography>
                                                                            <Typography variant="body1">
                                                                                <strong>Seçim:</strong> {desc.odd_name}
                                                                            </Typography>
                                                                            <Typography variant="body1">
                                                                                <strong>Oran:</strong> {desc.odd}
                                                                            </Typography>
                                                                            <Typography variant="body1">
                                                                                <strong>Tarih:</strong> {formatDateTime(desc.event_date)}
                                                                            </Typography>
                                                                        </Box> */}
                                                                        </>
                                                                    ))
                                                                }

                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default Mybets;
