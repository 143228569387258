import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Grid, Avatar } from '@mui/material';
import ares from '../img/ares.png';
import zeus from '../img/zeus.png';
import poseidon from '../img/poseidon.png';
import Cookies from 'js-cookie';
import Tutorial from '../component/Tutorial';
import translatedata from '../translate.json';
const CharacterCard = ({ character,setLoginOpen,isLoggedIn,aresfee,poseidonfee,zeusfee,selectedLanguage }) => {

  const [hovered, setHovered] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if(isLoggedIn==false){
      setLoginOpen(true)
    }else{
      navigate(`/${character.name}`);
    }
 
  };

  
  return (
    <Card 
      sx={{ 
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        backgroundColor: "#1a1a1c",
        flexDirection: 'column', 
        position: 'relative',
        '&:hover': { 
          transform: 'scale(1.05)', 
          zIndex: 1, 
        } 
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
          {isLoggedIn&&
      <Box 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          right: 0, 
          backgroundColor: "#1a1a1c",
          color: '#ece4d4', 
          padding: '4px 4px', 
          borderTopRightRadius: '4px', 
          borderBottomLeftRadius: '4px',
          zIndex: 2 
        }}
      >
        {character.name=="Ares"&&aresfee+"%"}
        {character.name=="Poseidon"&&poseidonfee+"%"}
        {character.name=="Zeus"&&zeusfee+"%"}
      </Box>}
  
      <Box 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          backgroundColor: "#1a1a1c",
          color: '#ece4d4', 
          padding: '4px 4px', 
          borderTopLeftRadius: '4px', 
          borderBottomRightRadius: '4px',
          backgroundColor:"#d79f09"
        }}
      >
        {character.name}
      </Box>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Avatar
          alt={character.name}
          src={character.img}
          sx={{ width: 200, height: 200, marginBottom: 2 }}
        />
        <Typography variant="h6" align="center" gutterBottom sx={{color:"#ece4d4"}}>
          {character.name}
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary" sx={{color:"#ece4d4"}}>
          {character.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CharacterGrid = ({setLoginOpen,isLoggedIn,aresfee,poseidonfee,zeusfee,selectedLanguage}) => {
  const [tutorialid,setTutorialid]=useState(0);
  const [tutorialopen,setTutorialOpen]=useState(false);
  const characters = [

    {
      name: translatedata[selectedLanguage].maincard.Poseidon,
      description: translatedata[selectedLanguage].maincard.poseidonpart1+(poseidonfee>0?poseidonfee+translatedata[selectedLanguage].maincard.poseidonpart2:40+translatedata[selectedLanguage].maincard.poseidonpart2),
      discount: '%40',
      img: poseidon
    },
    {
      name: translatedata[selectedLanguage].maincard.Zeus,
      description:translatedata[selectedLanguage].maincard.zeusdesc,
      discount: '%20',
      img: zeus
    }
  ];
  useEffect(() => {
    if(isLoggedIn){
      const loginfirst=Cookies.get('loginfirst');
      const tutorial=Cookies.get('tutorial');
      console.log(loginfirst)
      if(loginfirst){
        if(!tutorial=="2" && !loginfirst=="2" ){
          setTutorialOpen(true)
          setTutorialid(0)
        }
      }else{
        setTutorialOpen(true)
        setTutorialid(0)
      }
    }
    }, [isLoggedIn])
  return (
    <>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "2.5rem" }}>
      <Grid container sx={{ maxHeight: "120rem", maxWidth: "60rem", backgroundColor: "#ece4d4", borderRadius: "1rem" }}>
        {characters.map((character, index) => (
          <Grid item xs={12} md={6} sx={{ padding: "16px" }} key={index} >
            <CharacterCard character={character} setLoginOpen={setLoginOpen} isLoggedIn={isLoggedIn} aresfee={aresfee} poseidonfee={poseidonfee}  zeusfee={zeusfee} />
          </Grid>
        ))}
      </Grid>
    </Box>
         <Tutorial tutorialopen={tutorialopen} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid}/>
         </>
  );
};

export default CharacterGrid;
