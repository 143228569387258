import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate,useLocation  } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import {
    AppBar, Toolbar, IconButton, Typography, Container, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Icon
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import translatedata from '../translate.json';


const Mybets = ({selectedLanguage}) => {
    
    const navigate = useNavigate();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
      const query = useQuery();
      const [token, setToken] = useState('');
      const [success,setSuccess]= useState(false);
      const [cookies, setCookie] = useCookies(['serviceToken']);
      useEffect(() => {
        const tokenValue = query.get('token');
        setToken(tokenValue);
        const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          };
    
          if (cookies.serviceToken) {
            config.headers['Authorization'] = `Bearer ${cookies.serviceToken}`;
          }
    
          axios.post("https://api.youbetshop.net/verifymailtoken", { token: tokenValue }, config)
            .then(response => {
              if (response) {
                if (response.data.success === true) {
                  setSuccess(true);
                }else{
                    setSuccess(false);
                }
              }
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              setSuccess(false);
            });
        
      }, []);


      
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">
                        {translatedata[selectedLanguage].mailverify.home}
                    </Typography>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                    </Typography>
                    <Typography variant="h6" >
                       {translatedata[selectedLanguage].mailverify.verifymail}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
            <Typography variant="h4" sx={{ color: "#333", padding: "15px" }}>
            {translatedata[selectedLanguage].mailverify.verifymail}
          </Typography>
            <Box my={4} sx={{ backgroundColor: "#ededed", borderRadius: "1rem" }}>
      <Grid container sx={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
        <Grid item xs={12} >

        </Grid>
        <Grid item xs={12} sx={{ padding: "10px 0" }}>
          {success === true ? (
            <>
                      <img
                      src={`/image/success.png`}
                      alt={"success"}
                      style={{ width: '20%', height: 'auto', marginBottom: '8px', marginTop: "10px" }}
                    />
            <Typography variant="h4">{translatedata[selectedLanguage].mailverify.successverify}</Typography>
            </>
          ) : (
            <>
            <img
            src={`/image/error.png`}
            alt={"error"}
            style={{ width: '2memm0%', height: 'auto', marginBottom: '8px', marginTop: "10px" }}
          />
            <Typography variant="h4">{translatedata[selectedLanguage].mailverify.errorverify}</Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
            </Container>
        </>
    );
}

export default Mybets;
