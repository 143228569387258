import React, { useState,useEffect } from 'react';
import { TextField, Button, Snackbar, Alert, Container, AppBar, Toolbar, IconButton, Typography, Grid, useTheme, InputAdornment, Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import StarIcon from '@mui/icons-material/Star';
import translatedata from '../translate.json';
import Tutorial from '../component/Tutorial';
import Cookies from 'js-cookie';
const Starrace = ({isLoggedIn,selectedLanguage}) => {
  const [confirm, setConfirm] = useState();
  const [stardetail, setStarDetail] = useState(); // info, success, error
  const [stars, setStars] = useState();
  const [affstars, setAffStars] = useState();
  const [starsconf, setStarsConf] = useState([]);
  const [affiusers, setAffiUsers] = useState([]);
  const [promocode, setPromoCode] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('error');
  const [affiusername, setAffiusername] = useState();
  const [promo_code,setPromo]= useState();
  const query = new URLSearchParams(location.search);
  const hash = query.get('hash');
  const reqid = query.get('reqid');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [tutorialid,setTutorialid]=useState(4);
  const [tutorialopen,setTutorialOpen]=useState(false);
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const recheck=()=>{
    const token = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.net/api/user/mystarrace","",config)
      .then(response => {
        setStarDetail(JSON.parse(response.data.data.star_detail))
        setStars(response.data.data.stars);
        setAffStars(response.data.data.affi_stars);
        setStarsConf(response.data.data.star_conf);
        setAffiUsers(response.data.data.affi_users)
        setPromoCode(response.data.data.promo_code)
        setAffiusername(response.data.data.affiusername)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }
  useEffect(() => {
    const token = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.net/api/user/mystarrace","",config)
      .then(response => {
        setStarDetail(JSON.parse(response.data.data.star_detail))
        setStars(response.data.data.stars);
        setAffStars(response.data.data.affi_stars);
        setStarsConf(response.data.data.star_conf);
        setAffiUsers(response.data.data.affi_users)
        setPromoCode(response.data.data.promo_code)
        setAffiusername(response.data.data.affiusername)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }, [isLoggedIn])
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
const savePromocode =()=>{
  const token = cookies.serviceToken;
  const config = {
      headers: { Authorization: `Bearer ${token}` },
  };
  let param={promo_code:promo_code}
  axios.post("https://api.youbetshop.net/api/user/updatepromocode",param,config)
    .then(response => {
      if(response.data.success==true){
        setMessage(response.data.message);
        setStat('success');
        setOpenSnack(true);
        recheck();
      }else{
        setMessage(response.data.message);
        setStat('error');
        setOpenSnack(true);
      }
    })
    .catch(error => {
      console.error('Hata', error);
      setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
      setStat('error');
      setOpenSnack(true);
    });
}
useEffect(() => {
  if (isLoggedIn) {
    console.log("User is logged in.");
    const tutorial = Cookies.get('tutorial');
    console.log("Tutorial cookie:", tutorial);
    if (tutorial !== "2") {
      setTutorialOpen(true);
      setTutorialid(4);
    }
  }
}, [isLoggedIn]);
  // Mevcut domaini kullanarak site URL'sini oluşturma
  const siteUrl = `${window.location.origin}?promocode=${promocode}`;
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h6">
              {translatedata[selectedLanguage].starrace.home}
            </Typography>
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
          </Typography>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
            {translatedata[selectedLanguage].starrace.starrace}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40vh'
        }}
      >
     {isLoggedIn?
      
          <>
          <Grid container spacing={2} sx={{marginTop:"30px"}}>
          <Grid container spacing={2} sx={{ marginTop: "30px" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginLeft:"15px"}}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={isMobile ? 'flex-start' : 'space-between'}
            alignItems={isMobile ? 'flex-start' : 'center'}
          >
            <Box display="flex" alignItems="center" sx={{ textAlign: "left", marginBottom: isMobile ? 2 : 0 }}>
              <Typography>{translatedata[selectedLanguage].starrace.youtpromocode}<br/> {promocode}</Typography>
              <IconButton onClick={() => handleCopy(promocode)} sx={{ marginLeft: 1 }}>
                <FileCopyIcon />
              </IconButton>
            </Box>
            {affiusername?

                        <Box display="flex" alignItems="center" sx={{ textAlign: "center", marginBottom: isMobile ? 2 : 0 }}>
                        <Typography>{translatedata[selectedLanguage].starrace.youraffi}<br/> {affiusername}</Typography>
                      </Box>
            :
            <Box display="flex" alignItems="center" sx={{ textAlign: "center", marginBottom: isMobile ? 2 : 0 }}>
              <TextField
            margin="dense"
            label={translatedata[selectedLanguage].starrace.promocodewrite}
            type={'text'}
            fullWidth
            value={promo_code}
            onChange={(e) => setPromo(e.target.value)}
          />
              <IconButton  onClick={() => savePromocode()} sx={{ marginLeft: 1,backgroundColor:"#29963a" }}>
                <CheckIcon  />
              </IconButton>
            </Box>
            }
            <Box display="flex" alignItems="center" sx={{ textAlign: isMobile ? 'left' : 'right' }}>
              <Typography>
                {translatedata[selectedLanguage].starrace.yourpromolink}<br/>
                <a href={siteUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#1976d2', marginLeft: 5 }}>
                  {siteUrl}
                </a>
              </Typography>
              <IconButton onClick={() => handleCopy(siteUrl)} sx={{ marginLeft: 1 }}>
                <FileCopyIcon />
              </IconButton>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>


          <Grid item xs={12} sm={12} md={6} lg={6}>
      <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
        <Box textAlign="center" marginBottom={2}>
          <Typography variant="h4" sx={{ color: "#333" }}>
            {translatedata[selectedLanguage].starrace.starracemission}
          </Typography>
          <Typography sx={{ color: "#333" }}>
          {translatedata[selectedLanguage].starrace.starracetext}
          </Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.missionname}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.missionstatus}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.starcount}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stardetail?.map((item, index) => (
                <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4' }}>
                  <TableCell>{item.taskname}</TableCell>
                  <TableCell sx={{ color: item.status === "1" ? 'green' : 'inherit', fontWeight: 'bold' }}>
                    {item.status === "1" ? '✓' : translatedata[selectedLanguage].starrace.waiting}
                  </TableCell>
                  <TableCell>
                    {item.value} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
        <Box textAlign="center" marginBottom={2}>
          <Typography variant="h4" sx={{ color: "#333" }}>
          {translatedata[selectedLanguage].starrace.starpoint}
          </Typography>
          <Typography sx={{ color: "#333" }}>
          {translatedata[selectedLanguage].starrace.starracetext2}
          </Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}></TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.star}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow  >
                <TableCell>{translatedata[selectedLanguage].starrace.totalstar}</TableCell>
                  <TableCell>{stars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} /></TableCell>
                </TableRow>
                <TableRow  >
                <TableCell>{translatedata[selectedLanguage].starrace.affistar}</TableCell>
                  <TableCell>{affstars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} /></TableCell>
                </TableRow>
                <TableRow  >
                <TableCell>{translatedata[selectedLanguage].starrace.poseidonstarcount}</TableCell>
                  <TableCell>{starsconf.poseidon_value} % </TableCell>
                </TableRow>
                <TableRow  >
                <TableCell>{translatedata[selectedLanguage].starrace.zeusstarcount}</TableCell>
                  <TableCell>{starsconf.zeus_value} % </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
      <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
        <Box textAlign="center" marginBottom={2}>
          <Typography variant="h4" sx={{ color: "#333" }}>
            {translatedata[selectedLanguage].starrace.affiliatenet}
          </Typography>
          <Typography sx={{ color: "#333" }}>
            {translatedata[selectedLanguage].starrace.starracetext3}
          </Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.username}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.starcountamo}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {affiusers?.map((user, index) => (
                <TableRow key={user.id} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4' }}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>
                    {user.stars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
          </Grid>
          </>
          :      
          <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>    
          <Typography  sx={{ color: "#333" }}>
              {translatedata[selectedLanguage].starrace.pleaselogin}
            </Typography> </Paper> }
        
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={stat}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Container>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Tutorial tutorialopen={tutorialopen} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid}/>
    </>
  );
};

export default Starrace;
