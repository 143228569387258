import React, { useEffect, useState } from 'react';
import ares from '../img/ares.png';
import axios from 'axios';
import { Button, Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Grid, AppBar, Toolbar, IconButton, Tooltip, Snackbar, Alert, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import translatedata from '../translate.json';

const Ares = ({ recheck,balance,blockbalance,setBalance,setBlockBalance,newbalance,blockedbalance,aresfee, selectedLanguage}) => {
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [data, setData] = useState([]);
    const [totalPrice, setTotalPrice] = useState();
    const [winvalue, setWinvalue] = useState();
    const [nowbalance, setNowBalance] = useState(balance);
    const [nowblockbalance, setNowBlockBalance] = useState(blockbalance);
    const [coupon, setCoupon] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [stat, setStat] = useState(false);
    const [pass, setPass] = useState();
    const [opentool, setOpenTool] = useState(false);
    const [maxwin, setMaxwin] = useState();
    const [aresid, setAresid] = useState();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    useEffect(() => {
        setNowBalance(newbalance)
        setNowBlockBalance(blockedbalance)
        setBalance(newbalance)
        setBlockBalance(blockedbalance)
      }, [newbalance,blockedbalance])
    useEffect(() => {
        const accessToken = cookies.serviceToken;
        setWinvalue('');
        if (!accessToken) {
            console.error('No access token found');
            return;
        }

        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };

        axios.get("https://api.youbetshop.net/api/coupon/coupon-ares", config)
            .then(response => {
                setPass(response.data.pass_right);
                setData(response.data.data);
                setMaxwin(response.data.data[0]?.total_amount);
                setAresid(response.data.data[0]?.id);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const passcoup = () => {
        const accessToken = cookies.serviceToken;
        setWinvalue('');
        if (!accessToken) {
            console.error('No access token found');
            return;
        }

        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };

        axios.get("https://api.youbetshop.net/api/coupon/coupon-ares", config)
            .then(response => {
                setPass(response.data.pass_right);
                setMaxwin(response.data.data[0]?.total_amount);
                setData(response.data.data);
                setAresid(response.data.data[0]?.id);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        let interval;
        if (aresid) {
            interval = setInterval(() => {
                const accessToken = cookies.serviceToken;
                if (!accessToken) {
                    console.error('No access token found');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                };

                axios.post("https://api.youbetshop.net/api/coupon/verify-ares",{ ares_id:aresid },config)
                    .then(response => {
                        setPass(response.data.pass_right);
                        setMaxwin(response.data.data[0]?.total_amount);
                        setData(response.data.data);
                    })
                    .catch(error => {
                        console.error('Error verifying data:', error);
                    });
            }, 10000); // 10 seconds

            return () => clearInterval(interval);
        }
    }, [aresid]);
    const addCoup = (matchid, marketid, oddid, price) => {
        setCoupon(prevCoupon => {
            const existingIndex = prevCoupon.findIndex(coup =>
                coup.matchid === matchid &&
                coup.marketid === marketid &&
                coup.oddid === oddid
            );

            if (existingIndex !== -1) {
                return prevCoupon.filter((_, index) => index !== existingIndex);
            }

            const matchIndex = prevCoupon.findIndex(coup => coup.matchid === matchid);

            if (matchIndex !== -1) {
                const newCoupon = [...prevCoupon];
                newCoupon[matchIndex] = { matchid, marketid, oddid, price };
                return newCoupon;
            }

            return [...prevCoupon, { matchid, marketid, oddid, price }];
        });
    };

    const isButtonActive = (oddid, matchid) => {
        return coupon.some(coup => coup.oddid === oddid && coup.matchid === matchid);
    };

    useEffect(() => {
        const total = coupon.reduce((acc, item) => acc * item?.price, 1);
        setTotalPrice(total.toFixed(2));
    }, [coupon]);
    const handleTooltipOpen =()=>{
        if(opentool==true){
            setOpenTool(false)
        }else{
            setOpenTool(true)
            setTimeout(() => {
                handleTooltipClose();
            }, 5000);
        }
    }
    const handleTooltipClose =()=>{
        setOpenTool(false)
    }
    const sendCoupon = () => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        let params = { ares_id: data[0].id, stake: winvalue };
        axios.post("https://api.youbetshop.net/api/coupon/coupon-ares-create", params, config)
            .then(response => {
                if (response.data.data.success) {
                    setMessage(translatedata[selectedLanguage].notify.couponcreated);
                    setOpen(true);
                    setStat("success");
                    setMaxwin(response.data.data.data.last_total_amount);
                    setWinvalue('');
                } else {
                    setMessage(response.data.data.message);
                    setOpen(true);
                    setStat("error");
                }
                recheck();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setMessage('Bir hata oluştu');
                setOpen(true);
                setStat("error");
            });
    };

    const dateformat = (dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setHours(originalDate.getHours() - 3);
        const formattedDate = new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        }).format(originalDate);

        return formattedDate;
    };
    function formatNumber(number) {
        return new Intl.NumberFormat('tr-TR', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
    }
    useEffect(() => {
        if(winvalue>0){
            setBalance(formatNumber((nowbalance-(winvalue* (data[0]?.odds-1))).toFixed(2)))
            setBlockBalance(formatNumber((nowblockbalance+(winvalue* (data[0]?.odds-1))).toFixed(2)))
        }else{
            setBalance(formatNumber(nowbalance))
            setBlockBalance(formatNumber(nowblockbalance))
        }
        
    }, [winvalue])
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => {navigate("/"),setBalance(formatNumber(nowbalance)),
            setBlockBalance(formatNumber(nowblockbalance))}}>
                        <ArrowBackIcon />
                        <Typography variant="h6">
                         {translatedata[selectedLanguage].ares.home}
                        </Typography>
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                    </Typography>
                    <Typography variant="body1" style={{ marginRight: '10px' }}>
                    {translatedata[selectedLanguage].ares.comission+": %"+aresfee}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container sx={{marginBottom:"30px",marginTop:"50px",backgroundColor: "#ece4d4",padding:"20px",borderRadius:"15px"}}>
            <Box my={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
  <img src={ares} alt="Ares" style={{ width: '150px', height: '150px', borderRadius: '10px' }} />
  <Box ml={2} textAlign="left">
    <Typography variant="h4" display="block">
      Ares
      <Tooltip
        title={
          <Typography variant="body2">
            {translatedata[selectedLanguage].ares.aresdet}
          </Typography>
        }
        arrow
        PopperProps={{ disablePortal: true }}
        onClose={handleTooltipClose}
        open={opentool}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton  onClick={handleTooltipOpen}>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </Typography>
  </Box>
</Box>


                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ backgroundColor: "#d5d5d5" }}>
                            <TableRow style={{ padding: isMobile ? "5px" : "16px" }}>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{translatedata[selectedLanguage].ares.eventname}</TableCell>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{translatedata[selectedLanguage].ares.date}</TableCell>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{translatedata[selectedLanguage].ares.market}</TableCell>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{translatedata[selectedLanguage].ares.odd}</TableCell>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{translatedata[selectedLanguage].ares.oddprice}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data[0]?.description.map((item) => (
                                <TableRow key={item.event_name}>
                                    <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{item.event_name}</TableCell>
                                    <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{dateformat(item.event_date)}</TableCell>
                                    <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{translatedata[selectedLanguage].market[item.market_name]}</TableCell>
                                    <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{translatedata[selectedLanguage].odd[item.odd_name]}</TableCell>
                                    <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>{item.odd}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box mt={4} textAlign="center">
                    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={4} sm={3}>
                            <Typography variant="h6" sx={{fontSize:isMobile?"0.8rem":"1.25rem"}}>{translatedata[selectedLanguage].ares.totalodd}</Typography>
                            <Typography variant="h4" sx={{fontSize:isMobile?"0.8rem":"1.5rem"}} color="primary">{data[0]?.odds}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <Typography variant="h6" sx={{fontSize:isMobile?"0.8rem":"1.25rem"}}>{translatedata[selectedLanguage].ares.maxbuy}</Typography>
                            <Typography variant="h4" sx={{fontSize:isMobile?"0.8rem":"1.5rem",cursor:"pointer"}} color="primary" onClick={()=>
                                setWinvalue(maxwin)}>{winvalue ? (maxwin - winvalue).toFixed(2) : maxwin}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <Typography variant="h6" sx={{fontSize:isMobile?"0.8rem":"1.25rem"}}>{translatedata[selectedLanguage].ares.blokamount}</Typography>
                            <Typography variant="h4" sx={{fontSize:isMobile?"0.8rem":"1.5rem"}} color="secondary">{winvalue ? (winvalue * (data[0]?.odds-1)).toFixed(2) : 0}</Typography>
                        </Grid>
                     </Grid>
                        <Grid container spacing={2}  alignItems={isMobile?"flex-end":"flex-end"} justifyContent={"center"} sx={{marginTop:"5px"}}>
                        <Grid item xs={4} sm={3} sx={{paddingTop:"0px"}}><Button variant="contained" color="primary" sx={{backgroundColor:"#187700",padding:isMobile?"5px 16px":"16px 36px"}} onClick={sendCoupon}>{translatedata[selectedLanguage].ares.sendcoupon}</Button></Grid>
                        <Grid item xs={4} sm={3} sx={{paddingTop:"0px"}}>
                            <Typography variant="h6" sx={{fontSize:isMobile?"0.8rem":"1.25rem"}}>Kazanç:</Typography>
                            <TextField
                                type="number"
                                value={winvalue}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (maxwin && newValue > maxwin) {
                                        setWinvalue(maxwin);
                                        return;
                                    }
                                    setWinvalue(newValue);
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4} sm={3} sx={{paddingTop:"0px"}}><Button variant="contained" color="primary" onClick={passcoup} sx={{padding:"16px 36px" }}>{translatedata[selectedLanguage].ares.pass}</Button></Grid>
                 
                    </Grid>
                </Box>

            </Container>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={stat}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                    <Button sx={{ color: "#ece4d4", textDecoration: "underline" }} onClick={() => navigate("/my-bets")}>
                    {translatedata[selectedLanguage].ares.mybets}
                    </Button>
                </Alert>
            </Snackbar>
        </>
    );
};

export default Ares;
